// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Aktifo-B-ExtraBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Aktifo-B-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Larsseit-ExtraBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/Larsseit-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./assets/fonts/Larsseit.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./assets/fonts/Larsseit.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Aktifo';
  src: local('Aktifo B Extra Bold'), local('Aktifo-B-Extra-Bold'),
      url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
      url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}

@font-face {
  font-family: 'Larsseit ExtraBold';
  src: local('Larsseit ExtraBold'), local('Larsseit-ExtraBold'),
      url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),
      url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
}

@font-face {
  font-family: 'Larsseit';
  src: local('Larsseit Regular'), local('Larsseit-Regular'),
      url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2'),
      url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,qBAAqB;EACrB;;gEAEmE;AACrE;;AAEA;EACE,iCAAiC;EACjC;;gEAEmE;AACrE;;AAEA;EACE,uBAAuB;EACvB;;gEAEyD;AAC3D","sourcesContent":["body {\n  margin: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n@font-face {\n  font-family: 'Aktifo';\n  src: local('Aktifo B Extra Bold'), local('Aktifo-B-Extra-Bold'),\n      url('./assets/fonts/Aktifo-B-ExtraBold.woff2') format('woff2'),\n      url('./assets/fonts/Aktifo-B-ExtraBold.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Larsseit ExtraBold';\n  src: local('Larsseit ExtraBold'), local('Larsseit-ExtraBold'),\n      url('./assets/fonts/Larsseit-ExtraBold.woff2') format('woff2'),\n      url('./assets/fonts/Larsseit-ExtraBold.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Larsseit';\n  src: local('Larsseit Regular'), local('Larsseit-Regular'),\n      url('./assets/fonts/Larsseit.woff2') format('woff2'),\n      url('./assets/fonts/Larsseit.ttf') format('truetype');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
