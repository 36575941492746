// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hem {
  height: 100vh;
  width: 100%;
  color: #fff;
  font-size: 1.5em;
  font-weight: 300;
  padding: 0 10vw;
  gap: 32px;
  background-color: #00253a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hem__text .buttonContainer {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  gap: 32px;
  font-family: "Larsseit";
  font-weight: 600;
  letter-spacing: 5px;
  align-self: center;
}

.scrolldown {
  font-size: 20px;
  text-align: center;
  padding: 17px 18px 14px 18px;
  color: #fff;
  border-bottom-style: solid;
  border-width: 1px;
  text-decoration: none;
  cursor: default;
  line-height: normal;
  transition: letter-spacing 330ms ease-in-out;
}

.scrolldown:hover {
  color: #fff;
  letter-spacing: 7px;
  transition: letter-spacing 330ms ease-in-out;
}

@media screen and (max-width: 500px) {
  .hem__text {
    margin-top: -50px;
  }

  .hem__text h1 {
    font-size: 30px;
  }

  .hem__text h2 {
    font-size: 25px;
    font-family: "Larsseit";
  }

  .scrolldown {
    font-size: 25px;
    letter-spacing: 3px;
  }

  .hem img {
    height: 100px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/hem/_hem.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,SAAS;EACT,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,SAAS;EACT,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,4BAA4B;EAC5B,WAAW;EACX,0BAA0B;EAC1B,iBAAiB;EACjB,qBAAqB;EACrB,eAAe;EACf,mBAAmB;EACnB,4CAA4C;AAC9C;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,4CAA4C;AAC9C;;AAEA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,uBAAuB;EACzB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".hem {\n  height: 100vh;\n  width: 100%;\n  color: #fff;\n  font-size: 1.5em;\n  font-weight: 300;\n  padding: 0 10vw;\n  gap: 32px;\n  background-color: #00253a;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.hem__text .buttonContainer {\n  display: flex;\n  flex-direction: row;\n  margin-top: 32px;\n  gap: 32px;\n  font-family: \"Larsseit\";\n  font-weight: 600;\n  letter-spacing: 5px;\n  align-self: center;\n}\n\n.scrolldown {\n  font-size: 20px;\n  text-align: center;\n  padding: 17px 18px 14px 18px;\n  color: #fff;\n  border-bottom-style: solid;\n  border-width: 1px;\n  text-decoration: none;\n  cursor: default;\n  line-height: normal;\n  transition: letter-spacing 330ms ease-in-out;\n}\n\n.scrolldown:hover {\n  color: #fff;\n  letter-spacing: 7px;\n  transition: letter-spacing 330ms ease-in-out;\n}\n\n@media screen and (max-width: 500px) {\n  .hem__text {\n    margin-top: -50px;\n  }\n\n  .hem__text h1 {\n    font-size: 30px;\n  }\n\n  .hem__text h2 {\n    font-size: 25px;\n    font-family: \"Larsseit\";\n  }\n\n  .scrolldown {\n    font-size: 25px;\n    letter-spacing: 3px;\n  }\n\n  .hem img {\n    height: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
